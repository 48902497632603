import React from "react"
import { Col, Row, Card } from "react-bootstrap"

import PageLayout from "../../components/PageLayout"
import Hero from "../../components/Hero"
import SEO from "../../components/seo"

import LaurenImage from "./images/lauren.jpeg"
import TomImage from "./images/tom.jpeg"
import ContactForm from "../../components/ContactForm"

const AboutPage = () => (
  <PageLayout title={"Contact"} hero={<Hero title="Contact Freshnode" />}>
    <div className="mt-4">
      <SEO
        title="Contact"
        description="Human-centered technology solutions for businesses large and small"
      />
      <Row>
        <Col xs={12} lg={7}>
          <ContactForm />
        </Col>
        <Col xs={12} lg={5}>
          <Card>
            <Card.Body>
              <h2>Our Office</h2>
              <p>
                Saltworks
                <br />4 Ash Street
                <br />
                Christchurch Central City
                <br />
                Christchurch 8011
                <br />
                New Zealand
              </p>
              <hr />
              <h2>Prefer to pick up the phone?</h2>
              <p>
                We're available to talk from 9:00 am - 5:30 pm NZST, Monday to
                Friday.
              </p>
              <hr />
              <p className="lead">
                <span className="mr-2" role="img">
                  🇬🇧
                </span>
                <strong style={{ fontWeight: 700 }}>
                  If you're calling from the UK:
                </strong>
                <br />
                0117 318 1726
              </p>
              <hr />
              <p className="lead mb-0">
                <span className="mr-2" role="img">
                  🇳🇿
                </span>
                <strong style={{ fontWeight: 700 }}>
                  If you're calling from New Zealand:
                </strong>
                <br />
                036 690 491
              </p>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  </PageLayout>
)

export default AboutPage
